export default {
	path: '/group-code',
	redirect: '/group-code',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'groupCodeHome',
			component: () => import('@/views/mng/GrpCode'),
		},
	],
};
