export default {
	path: '/object-target',
	redirect: '/object-target',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'obejectTargetHome',
			component: () => import('@/views/mng/ObjectTarget'),
		},
	],
};
