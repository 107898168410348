import Vue from 'vue';
import _ from 'lodash';
import jarvisExtention from './JarvisExtention';
import VeeValidate from 'vee-validate';

/** Vue 전역필터 */
Vue.filter('toDisplayDateTime', jarvisExtention.converter.toDisplayDateTime);
Vue.filter('toDisplayDate', jarvisExtention.converter.toDisplayDate);
Vue.filter('toNumberFormat', jarvisExtention.converter.toNumberFormat);

/** Vue확장 (vaildator, extender) */
const vueExtention = {
	//Extender
	extender: {
		/**
		 * Vuex Store에 EventBus 확장
		 * [사용법] vueExtention.extender.extendEventBus(targetStore);
		 *          store.$bus.emit("search") => store.$bus.$on('search', this.load);
		 */
		extendEventBus(targetStore) {
			const _storeEventBus = new Vue(); //클로져에 store용 eventBus생성
			Object.defineProperties(targetStore, {
				//store인스턴스에 $bus로 eventBus getter정의
				$bus: {
					get: function () {
						return _storeEventBus;
					},
				},
			});
		},
	},
	//Vee-Validator Custom validation
	validator: {
		momentDateTime: {
			getMessage: field => `${field}항목의 날짜시간형식이 올바르지 않습니다.`,
			validate: value => {
				return jarvisExtention.converter.parseMoment(value).isValid();
			},
		},
		//휴대폰번호
		mobileNoPattern: {
			getMessage: field => `${field} 항목 형식이 올바르지 않습니다.(010123445678 또는 010-1234-5678)`,
			validate: value => {
				var regEx = /^01[016789]{1}[0-9]{7,8}$/;
				var regExDashed = /^01[016789]{1}-[0-9]{3,4}-[0-9]{4}$/;
				return regEx.test(value) || regExDashed.test(value);
			},
		},
		//휴대폰번호 숫자만
		mobileNoNumPattern: {
			getMessage: field => `${field} 항목은 최대 11자리 숫자로 입력해주세요. (01012345678)`,
			validate: value => {
				var regEx = /^01[016789]{1}[0-9]{7,8}$/;
				return regEx.test(value);
			},
		},
		//전화번호, 휴대폰번호 숫자만
		phoneNoAndMobileNoPattern: {
			getMessage: field => `${field} 항목은 최대 11자리 숫자로 입력해주세요. (01012345678, 021234567)`,
			validate: value => {
				var regExMobile = /^01[016789]{1}[0-9]{7,8}$/;
				var regExPhone = /^0[0-9]{1,2}[0-9]{3}[0-9]{4}$/;
				return regExMobile.test(value) || regExPhone.test(value);
			},
		},
		//모든 전화번호 형식
		phoneNoPattern: {
			getMessage: field => `${field} 항목은 숫자, -, (, )로만 입력해주세요.`,
			validate: value => {
				//validation
				var regEx = /^[0-9\x28\x29\x2d]{0,20}$/;
				return regEx.test(value);
			},
		},
		//숫자만 허용
		numOnly: {
			getMessage: field => `${field} 항목은 숫자만 입력해주세요.`,
			validate: value => {
				var regEx = /^[0-9]*$/;
				return regEx.test(value);
			},
		},
		//한글/영문만 허용
		lettersOnly: {
			getMessage: field => `${field} 항목은 한글,영문,숫자로만 입력해주세요.`,
			validate: value => {
				var regEx = /[a-z0-9가-힣]$/i;
				return regEx.test(value);
			},
		},
		//특수문자를 포함한 8자이상
		commaxPassword: {
			getMessage: field => `${field} 항목은 특수문자를 포함한 8자 이상으로 입력해주세요.`,
			validate: value => {
				var regEx = /^(?=.*[$!@#$%^&*()-_=+`~])[A-Za-z\d$!@#$%^&*()-_=+`~]{8,}/;
				return regEx.test(value);
			},
		},
		// 파일명에 영문 숫자만 일반기호만 허용
		fileNameCheck: {
			getMessage: field => `${field} 항목은 영문,숫자로,허용기호(.;-()_[]!)로 되어진 파일명만 등록하실 수 있습니다.`,
			validate: value => {
				if (_.isEmpty(value)) {
					return true;
				}

				var fileName = value[0].name;
				var regEx = /^[A-Za-z0-9.;\-()_\\[\]!]*$/;
				return regEx.test(fileName);
			},
		},
		alphaNumberCheck: {
			getMessage: field => `${field} 항목에는 영문/숫자/기본특수기호 만 입력할 수 있습니다.`,
			validate: value => {
				var regEx = /^[A-Za-z0-9.;\-()_\\[\]!]*$/;
				return regEx.test(value);
			},
		},
		kccObjectCheck: {
			getMessage: field => `${field} 항목에는 영문만 입력할 수 있습니다.`,
			validate: value => {
				var regEx = /^[A-Za-z]*$/;
				return regEx.test(value);
			},
		},
		imageFileCheck: {
			getMessage: field => `${field} 항목에는 이미지(png,jpg) 파일만 등록할 수 있습니다.`,
			validate: value => {
				if (_.isEmpty(value)) {
					return true;
				}

				var fileName = value[0].name;
				var regEx = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
				return regEx.test(fileName);
			},
		},
		userIdCheck: {
			getMessage: field => `${field} 항목에는 숫자 또는 영어만 입력 가능합니다. (최대 30자)`,
			validate: value => {
				var regEx = /^[A-Za-z0-9]{0,30}$/;
				return regEx.test(value);
			},
		},
		// , 로 분리해서 중복된 값이 있는지 확인
		duplicationCheck: {
			getMessage: field => `${field} 항목에는 중복된 값을 입력할 수 없습니다`,
			validate: value => {
				let values = value.replace(/ /gi, '').split(',');
				if (values.length != new Set(values).size) {
					return false;
				}
				return true;
			},
		},
		/*,
		commaxPassword: {
			getMessage: (field) => `${field} 항목은 숫자,영문,특수문자를 포함한 8자 이상으로 입력해주세요.`,
			validate: (value) => {
				var regEx = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$!@#$%^&*()-_=+`~])[A-Za-z\d$!@#$%^&*()-_=+`~]{8,}/;
				return regEx.test(value);
			}
		}
		*/
	},
};

/**
 * Vee-Validate 의 localize 전역설정
 *
 * Vee-Validate 의 localize 미설정 시 => custom rule의 메시지가 기본값인 en으로 등록됨.
 * 현재 각 페이지에서 ko로 localize를 개별설정하므로 en에 등록된 message를 찾지 못하는 이슈 발생.
 */
VeeValidate.Validator.localize('ko');

/** Vee-Validate 전역커스텀룰 설정 */
_(vueExtention.validator).each(function (value, key) {
	//https://baianat.github.io/vee-validate/guide/custom-rules.html#using-the-custom-rule
	VeeValidate.Validator.extend(key, value);
});

export default {
	vueExtention,
};
