import ableVuePlugin from './ableVuePlugin';
import ableExtendPlugin from './ableExtendPlugin';
import globalFilters from './globalFilters';
import globalLibraries from './globalLibraries';

const Plugins = {
	install(Vue) {
		Vue.use(ableVuePlugin);
		Vue.use(ableExtendPlugin);
		Vue.use(globalFilters);
		Vue.use(globalLibraries);
	},
};

export default Plugins;
