import moment from 'moment';
import _ from 'lodash';

////////////////////////////
// Able JS 확장
const ableExtention = {};

////////////////////////////////
// Able JS 확장 - Converter
ableExtention.converter = {
	/**
	 * DateTime포맷을 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 * @param convertFormat: 변경후 Format (기본값: "YYYY-MM-DDTHH:mm:ss.SSSZ")
	 */
	convertDateTimeFormat(value, parseFormat = 'YYYY-MM-DD HH:mm:ss', convertFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ') {
		if (_.isEmpty(value)) {
			return null;
		}
		let regDateMoment = moment(value, parseFormat);
		if (regDateMoment.isValid()) {
			return regDateMoment.format(convertFormat);
		} else {
			console.log('convertToDateTimeFormat 실패', 'value=' + value, 'parseFormat=' + parseFormat);
			return null;
		}
	},
	/**
	 * 특정 DateTime포맷을 ISO 표준 포맷으로 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toISODateTime(value, parseFormat = 'YYYY-MM-DD HH:mm:ss') {
		return ableExtention.converter.convertDateTimeFormat(value, parseFormat, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
	},
	/**
	 * DateTime포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toDisplayDateTime(value, convertFormat = 'YYYY-MM-DD HH:mm:ss') {
		return ableExtention.converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * Date포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD')
	 */
	toDisplayDate(value, convertFormat = 'YYYY-MM-DD') {
		return ableExtention.converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * 주어진 문자열을 moment 객체로 파싱 (허용되는 포맷 대부분의 DateTime형)
	 * @param value 파싱할 값
	 */
	parseMoment(value) {
		var availableFormat = [
			'YYYY-MM-DDTHH:mmZ',
			'YYYY-MM-DDTHH:mm:ssZ',
			'YYYY-MM-DDTHH:mm:ss.SSSZ',
			'YYYY-MM-DD HH:mmZ',
			'YYYY-MM-DD HH:mm:ssZ',
			'YYYY-MM-DD HH:mm:ss.SSSZ',
			'YYYY-MM-DDTHH:mm',
			'YYYY-MM-DDTHH:mm:ss',
			'YYYY-MM-DDTHH:mm:ss.SSS',
			'YYYY-MM-DD HH:mm',
			'YYYY-MM-DD HH:mm:ss',
			'YYYY-MM-DD HH:mm:ss.SSS',
			'YYYY-MM-DD',
		];
		return moment(value, availableFormat, true /*strict*/);
	},
	/**
	 * 주어진 문자열/Boolean을 Boolean으로 변경
	 * @return Boolean: true/false, String:'true'/'t'/'1' 이외에 false
	 */
	asBoolean(value) {
		if (_.isBoolean(value)) {
			return value;
		}
		if (_.isString(value)) {
			return _.contains(['true', 't', '1'], value);
		}
		return false;
	},
	/**
	 * 숫자를 numberFormatting
	 * @param value: 변경할 값
	 */
	toNumberFormat(value) {
		var convertedValue = String.toNumber(value);
		if (!_.isNaN(convertedValue)) {
			return String.numberFormat(convertedValue);
		}
		return value;
	},
};

const GlobalFilters = {
	install(Vue) {
		Vue.filter('toDisplayDateTime', ableExtention.converter.toDisplayDateTime);
		Vue.filter('toDisplayDate', ableExtention.converter.toDisplayDate);
		Vue.filter('toNumberFormat', ableExtention.converter.toNumberFormat);
	},
};

export default GlobalFilters;
