export default {
	path: '/role-group',
	redirect: '/role-group',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'roleGroupHome',
			component: () => import('@/views/mng/RoleGroup'),
		},
	],
};
