export default {
	path: '/site',
	redirect: '/site',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'siteHome',
			component: () => import('@/views/mng/Site'),
		},
	],
};
