export default {
	path: '/accident-prevent',
	redirect: '/accident-prevent',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'accidentPreventHome',
			component: () => import('@/views/biz/AccidentPrevent'),
		},
	],
};
