export default {
	path: '/notice',
	redirect: '/notice',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'noticeHome',
			component: () => import('@/views/mng/Notice'),
		},
	],
};
