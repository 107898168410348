import _ from 'lodash';
import moment from 'moment';

const addKeyPrefix = function (obj, prefix) {
	var keys = Object.keys(obj);
	var result = {};

	for (let i = 0; i < keys.length; i++) {
		var key = keys[i];
		result[prefix + key] = obj[key];
	}

	return result;
};

////////////////////////////
// Axios확장
/** Axios확장 */
const axiosExtention = {
	/**
	 * buildErrorHandler - Axios의 catch error 핸들러 생성
	 * @param fieldMap - 필드-필드명 맵
	 */
	buildErrorHandler(fieldMap) {
		let closure = {
			fieldMap: fieldMap,
		};
		let axiosErrorHandler = function (error) {
			if (error.response) {
				if (error.response && error.response.status == 401) {
					_.defer(function () {
						if (confirm(`로그인이 필요합니다. 로그인페이지로 이동하시겠습니까?`)) {
							// document.location.href = '/login.do';
							window.location.href = '/login';
						}
					});
					return;
				}

				//서버에러응답
				console.error('server error response: ', error.response);
				const data = error.response.data;
				if (data) {
					// let msg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(data.message) ? data.message : `서버의 요청처리에 예외가 발생하였습니다.\n에러메시지: ${data.message}`;
					let msg =
						/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(data.message) && !data.message.includes('JSON parse error')
							? data.message
							: '서버요청 처리 도중 예외가 발생했습니다.\n반복적으로 해당 메세지가 나타나면 관리자에게 문의해주세요.';
					msg = `${msg}\n\n`;
					let subErrors = data.subErrors || data.errors;
					let subMsg = _.map(subErrors, function (s) {
						let fieldName = s.field || s.objectName;
						if (closure.fieldMap) {
							fieldName = _.has(closure.fieldMap, fieldName) ? closure.fieldMap[fieldName] : fieldName;
						}
						return fieldName + ': ' + (s.message || s.defaultMessage);
					}).join('\n');
					alert(msg + subMsg);
				}
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
			//console.log(error.config);
		};
		return axiosErrorHandler;
	},
};

////////////////////////////
// Jarvis JS 확장
// const jarvisExtention = {};

const converter = {
	/**
	 * DateTime포맷을 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 * @param convertFormat: 변경후 Format (기본값: "YYYY-MM-DDTHH:mm:ss.SSSZ")
	 */
	convertDateTimeFormat(value, parseFormat = 'YYYY-MM-DD HH:mm:ss', convertFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ') {
		if (_.isEmpty(value)) {
			return null;
		}
		let regDateMoment = moment(value, parseFormat);
		if (regDateMoment.isValid()) {
			return regDateMoment.format(convertFormat);
		} else {
			console.log('convertToDateTimeFormat 실패', 'value=' + value, 'parseFormat=' + parseFormat);
			return null;
		}
	},
	/**
	 * 특정 DateTime포맷을 ISO 표준 포맷으로 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toISODateTime(value, parseFormat = 'YYYY-MM-DD HH:mm:ss') {
		return converter.convertDateTimeFormat(value, parseFormat, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
	},
	/**
	 * DateTime포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toDisplayDateTime(value, convertFormat = 'YYYY-MM-DD HH:mm:ss') {
		return converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * Date포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD')
	 */
	toDisplayDate(value, convertFormat = 'YYYY-MM-DD') {
		return converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * 주어진 문자열을 moment 객체로 파싱 (허용되는 포맷 대부분의 DateTime형)
	 * @param value 파싱할 값
	 */
	parseMoment(value) {
		var availableFormat = [
			'YYYY-MM-DDTHH:mmZ',
			'YYYY-MM-DDTHH:mm:ssZ',
			'YYYY-MM-DDTHH:mm:ss.SSSZ',
			'YYYY-MM-DD HH:mmZ',
			'YYYY-MM-DD HH:mm:ssZ',
			'YYYY-MM-DD HH:mm:ss.SSSZ',
			'YYYY-MM-DDTHH:mm',
			'YYYY-MM-DDTHH:mm:ss',
			'YYYY-MM-DDTHH:mm:ss.SSS',
			'YYYY-MM-DD HH:mm',
			'YYYY-MM-DD HH:mm:ss',
			'YYYY-MM-DD HH:mm:ss.SSS',
			'YYYY-MM-DD',
		];
		return moment(value, availableFormat, true /*strict*/);
	},
	/**
	 * 주어진 문자열/Boolean을 Boolean으로 변경
	 * @return Boolean: true/false, String:'true'/'t'/'1' 이외에 false
	 */
	asBoolean(value) {
		if (_.isBoolean(value)) {
			return value;
		}
		if (_.isString(value)) {
			return _.includes(['true', 't', '1'], value);
		}
		return false;
	},
	/**
	 * 숫자를 numberFormatting
	 * @param value: 변경할 값
	 */
	toNumberFormat(value) {
		// eslint-disable-next-line no-undef
		var convertedValue = s.toNumber(value);
		if (!_.isNaN(convertedValue)) {
			// eslint-disable-next-line no-undef
			return s.numberFormat(convertedValue);
		}
		return value;
	},
};

////////////////////////////
// 정규식을 parameter로 받아 value에서 정규식을 검사해 제거 후 return 한다
// inputDataController에서 사용
const dataCheck = function (regExp, value) {
	if (!regExp.test(value)) {
		return value;
	} else {
		let condition = regExp.test(value);

		while (condition) {
			let regIndex = value.search(regExp);
			if (regIndex == 0) {
				value = value.slice(regIndex + 1, value.length);
			} else if (regIndex == value.length - 1) {
				value = value.slice(0, regIndex);
			} else {
				value = value.slice(0, regIndex) + value.slice(regIndex + 1, value.length);
			}

			condition = regExp.test(value);
		}

		return value;
	}
};

////////////////////////////
// 허용 길이(len)와 값(value)을 받아 허용 길이를 넘어가면 value길이를 제한하여 return한다.
// inputDataController에서 사용
const lenCheck = function (len, value) {
	try {
		if (typeof len !== 'number') len = parseInt(len);
	} catch {
		return value;
	}

	if (value.length > len) {
		value = value.slice(0, len);
		return value;
	} else {
		return value;
	}
};

////////////////////////////
// input에 들어온 값을 검사해 허용하지 않는 문자열을 제거한다.
//
const inputDataController = {
	onlyEngNum(value) {
		const regExp = /[^a-zA-Z0-9]/;
		// 정규식 체크
		value = dataCheck(regExp, value);
		return value;
	},
	onlyCapital(value) {
		const regExp = /[^A-Z]/;
		value = dataCheck(regExp, value);
		return value;
	},
	onlyCapComma(value) {
		const regExp = /[^A-Z,]+$/;
		value = dataCheck(regExp, value);
		return value;
	},
	onlyNumComma(value) {
		const regExp = /[^0-9,]+$/;
		value = dataCheck(regExp, value);
		return value;
	},
	onlyNumLimitLen(value, len) {
		const regExp = /[^0-9]/;

		// 정규식 체크
		value = dataCheck(regExp, value);
		// 길이 체크
		value = lenCheck(len, value);

		return value;
	},
	limitLen(value, len) {
		// 길이 체크
		value = lenCheck(len, value);

		return value;
	},
};

////////////////////////////////
// Jarvis JS 확장 - Converter
export default {
	axiosExtention,
	converter,
	addKeyPrefix,
	inputDataController,
};
