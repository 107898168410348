export default {
	path: '/menu',
	redirect: '/menu',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'menuHome',
			component: () => import('@/views/mng/Menu'),
		},
	],
};
