export default {
	path: '/i-main',
	redirect: '/i-main',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'ironMainHome',
			component: () => import('@/views/main/IronMain'),
		},
	],
};
