export default {
	path: '/cctv-danger-area-config',
	redirect: '/cctv-danger-area-config',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'cctvDangerAreaConfigViewHome',
			component: () => import('@/views/mng/CctvDangerAreaConfig'),
		},
	],
};
