export default {
	path: '/login',
	redirect: '/login',
	component: () => import('@/views/layout/Default.empty'),
	children: [
		{
			path: '/',
			name: 'loginHome',
			component: () => import('@/views/main/Login'),
		},
	],
};
