export default {
	path: '/warn-work-occur-video',
	redirect: '/warn-work-occur-video',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'warnWorkOccurVideoHome',
			component: () => import('@/views/biz/WarnWorkOccurVideo'),
		},
	],
};
