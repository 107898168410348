export default {
	path: '/common-code',
	redirect: '/common-code',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'commCodeHome',
			component: () => import('@/views/mng/CommCode'),
		},
	],
};
