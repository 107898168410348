import _ from 'lodash';

const AbleVuePlugin = {
	// install(Vue, options) {
	install(Vue) {
		// 컴포넌트 Mixin
		Vue.mixin({
			// Created시 Vue인스턴스 name과 options를 logging
			created() {
				const name = this.$options.name || undefined;
				if (name) {
					if (window.debug) {
						console.log('name: ', name, 'options:', this.$options);
					}
				}
			},
			// data에 전역 추가
			data() {
				return {
					me: window.me || {},
					debug: window.debug || false,
					pageParam: window.pageParam || {},
				};
			},
			// method에 전역추가
			methods: {
				/** 로그인되어 있는가? */
				isLogined() {
					return !_.isEmpty(this.me) && !_.isEmpty(this.me.username);
				},
				/** 특정권한을 가지고 있는가? */
				hasRole(roleCode) {
					return _.contains(
						_(this.me.authorities).map(i => i.authority),
						roleCode,
					);
				},
				/** 주어진 권한목록중 가지고 있는 권한이 있는가? */
				hasAnyRole(roleCode) {
					return (
						_.size(
							_.intersection(
								_(this.me.authorities).map(i => i.authority),
								roleCode,
							),
						) > 0
					);
				},
			},
		});
	},
};

export default AbleVuePlugin;
