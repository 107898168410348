import axios from 'axios';
import VueCookies from 'vue-cookies';
import jwt from 'jsonwebtoken';
import apiIndex from '../api/index';
import _ from 'lodash';

const jwtTokenAuthCheck = function () {
	// cookie access token의 유효시간 확인
	let accessToken = VueCookies.get('V2_ACCESS_TOKEN');

	let decodedAccessToken = jwt.decode(accessToken);

	// 쿠키에 Access Token이 없을때
	if (!accessToken) {
		return false;
	}

	// Access Token 존재하면 유지시간 계산
	let expireTime = new Date(decodedAccessToken.exp * 1000);
	let currentTime = new Date();

	// 남은 시간 timestamp 를 시간으로 보이게 계산
	let remainTime = (expireTime - currentTime) / 1000 / 60 / 60;

	// 토큰 유지시간 만료
	if (remainTime <= 0) {
		return false;
	} else if (!decodedAccessToken.siteId || decodedAccessToken.siteId == '') {
		// siteId가 없으면 false
		return false;
	} else {
		return true;
	}
};

$.blockUI.defaults = _.defaults(
	{
		message:
			location.pathname.includes('dashboard') || location.pathname.includes('main')
				? ''
				: '<img style="padding: 5px; background-color: #ffffff90; border: 1px solid #adadad;" src="/images/spinner_60.svg">',
		css: {
			padding: 0,
			margin: 0,
			width: '30%',
			top: '40%',
			left: '35%',
			textAlign: 'center',
			cursor: 'wait',
		},
		overlayCSS:
			location.pathname.includes('dashboard') || location.pathname.includes('main')
				? {}
				: {
						backgroundColor: '#000',
						opacity: 0.2,
						cursor: 'wait',
				  },
	},
	$.blockUI.defaults,
);

// 메세지 한번만 띄우기 위해 사용
let msgChk = false;

export default {
	axios() {
		axios.interceptors.request.use(
			config => {
				$.blockUI();
				if (config.url.includes('openweathermap')) {
					return config;
				}

				// cookie access token의 유효시간 확인
				let tokenChk = jwtTokenAuthCheck();

				let accessToken = VueCookies.get('V2_ACCESS_TOKEN');

				if (config.url.includes('login') || config.url.includes('inqUserSite')) {
					if (tokenChk) {
						window.location.href = apiIndex.mainUrl;
						return;
					}
					return config;
				} else if (config.url.includes('setUserSiteId')) {
					config.headers['V2_ACCESS_TOKEN'] = accessToken;
					return config;
				} else {
					// access token이 없거나 유효시간 만료되면 login 화면으로
					if (!accessToken || !tokenChk) {
						if (!msgChk) {
							alert('로그인이 필요합니다.\n로그인 페이지로 이동합니다.');
							msgChk = true;
						}
						window.location.href = '/login';
						return;
					}

					// request Header 토큰 전송
					config.headers['V2_ACCESS_TOKEN'] = accessToken;
					return config;
				}
			},
			error => {
				$.unblockUI();
				return Promise.reject(error);
			},
		);

		axios.interceptors.response.use(
			response => {
				if (response.data) {
					if (response.data.token) {
						// 쿠키에 Access Token 저장
						VueCookies.set('V2_ACCESS_TOKEN', response.data.token ? response.data.token : '', { expires: 60 * 60 * 3 });
						// VueCookies.set('V2_ACCESS_TOKEN', response.data.token ? response.data.token : '', { expires: 60 }); // test용 60초

						// response.data.data를 순서에 맞게 바꿔줌
						if (response.data.data) {
							response.data = response.data.data;
						}
					} else if (response.data.data) {
						response.data = response.data.data;
					}

					if (response.data.redirectUrl) {
						let redirectUrl = response.data.redirectUrl;
						if (window.location.pathname != redirectUrl) window.location.href = redirectUrl;
					}
				} else {
					// response 구조 변경되면 여기서 확인
				}
				$.unblockUI();
				return response;
			},
			error => {
				$.unblockUI();
				return Promise.reject(error);
			},
		);
	},
};
