export default {
	path: '/recently-notice',
	redirect: '/recently-notice',
	component: () => import('@/views/layout/Default.empty'),
	children: [
		{
			path: '/',
			name: 'recentlyNoticeHome',
			component: () => import('@/views/main/RecentlyNotice'),
		},
	],
};
